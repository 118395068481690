define("@adopted-ember-addons/ember-stripe-elements/components/stripe-elements", ["exports", "@ember/component", "@adopted-ember-addons/ember-stripe-elements/templates/components/stripe-elements", "@ember/service", "@ember/object"], function (_exports, _component, _stripeElements, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    stripe: (0, _service.inject)('stripev3'),
    tagName: '',
    layout: _stripeElements.default,

    init() {
      this._super(...arguments);

      let options = (0, _object.get)(this, 'options') || {};
      let elements = (0, _object.get)(this, 'stripe').elements(options);
      (0, _object.set)(this, 'elements', elements);
    }

  });

  _exports.default = _default;
});