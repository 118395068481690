define("@adopted-ember-addons/ember-stripe-elements/services/stripev3", ["exports", "@ember/service", "@ember/object", "@ember/object/computed", "rsvp", "@adopted-ember-addons/ember-stripe-elements/utils/load-script", "@ember/error", "@ember/array"], function (_exports, _service, _object, _computed, _rsvp, _loadScript, _error, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* global Stripe */
  // As listed at https://stripe.com/docs/stripe-js/reference#the-stripe-object
  const STRIPE_FUNCTIONS = ['elements', 'confirmCardPayment', 'createToken', 'createSource', 'createPaymentMethod', 'retrieveSource', 'paymentRequest', 'redirectToCheckout', 'retrievePaymentIntent', 'handleCardPayment', 'handleCardAction', 'confirmPaymentIntent', 'handleCardSetup', 'confirmCardSetup', 'retrieveSetupIntent', 'confirmSetupIntent'];

  var _default = _service.default.extend({
    config: null,
    didConfigure: false,
    didLoad: false,
    lazyLoad: (0, _computed.readOnly)('config.lazyLoad'),
    mock: (0, _computed.readOnly)('config.mock'),
    publishableKey: null,
    stripeOptions: null,

    init() {
      this._super(...arguments);

      this.set('publishableKey', this.get('config.publishableKey'));
      this.set('stripeOptions', this.get('config.stripeOptions'));
      let lazyLoad = this.get('lazyLoad');
      this.set('_stripeElements', (0, _array.A)());

      if (!lazyLoad) {
        this.configure();
      }
    },

    load(publishableKey = null, stripeOptions = null) {
      if (publishableKey) {
        this.set('publishableKey', publishableKey);
      }

      if (stripeOptions) {
        this.set('stripeOptions', stripeOptions);
      }

      let lazyLoad = this.get('lazyLoad');
      let mock = this.get('mock');
      let shouldLoad = lazyLoad && !mock;
      let doLoad = shouldLoad ? (0, _loadScript.default)("https://js.stripe.com/v3/") : (0, _rsvp.resolve)();
      return doLoad.then(() => {
        this.configure();
        this.set('didLoad', true);
      });
    },

    configure() {
      let didConfigure = this.get('didConfigure');

      if (!didConfigure) {
        let publishableKey = this.get('publishableKey');
        let stripeOptions = this.get('stripeOptions');

        if (!publishableKey) {
          throw new _error.default("stripev3: Missing Stripe key, please set `ENV.stripe.publishableKey` in config.environment.js");
        }

        let stripe = new Stripe(publishableKey, stripeOptions);
        let functions = (0, _object.getProperties)(stripe, STRIPE_FUNCTIONS);
        (0, _object.setProperties)(this, functions);
        this.set('didConfigure', true);
      }
    },

    addStripeElement(stripeElement) {
      this._stripeElements.pushObject(stripeElement);
    },

    removeStripeElement(stripeElement) {
      this._stripeElements.removeObject(stripeElement);
    },

    getActiveElements() {
      return [...this._stripeElements];
    }

  });

  _exports.default = _default;
});