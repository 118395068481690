define("ember-is-mobile/services/is-mobile", ["exports", "@ember/service", "@ember/application", "@ember/utils", "ember-is-mobile"], function (_exports, _service, _application, _utils, _emberIsMobile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  /**
   * The attributes returned by isMobile are accessible. However, they should be
   * accessed using the `get` helper, since they may be undefined if the user
   * agent header is blank.
   */
  class IsMobileService extends _service.default {
    get fastboot() {
      return (0, _application.getOwner)(this).lookup('service:fastboot');
    }

    constructor() {
      super(...arguments);

      _defineProperty(this, "tests", {});

      let tests;

      if (this.fastboot !== undefined && this.fastboot.isFastBoot) {
        let headers = this.fastboot.request.headers;
        let userAgent = headers.headers['user-agent']; // isMobile tries to fetch `navigator` if we give it a blank user agent.

        if ((0, _utils.isBlank)(userAgent)) {
          return;
        }

        tests = (0, _emberIsMobile.default)(userAgent[0]);
      } else {
        tests = (0, _emberIsMobile.default)();
      }

      for (let media in tests) {
        this[media] = tests[media];
      }

      this.tests = tests;
    }

  }

  _exports.default = IsMobileService;
});