define("ember-welcome-page/components/welcome-page", ["exports", "ember", "ember-welcome-page/templates/components/welcome-page"], function (_exports, _ember, _welcomePage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ember.default.Component.extend({
    layout: _welcomePage.default,
    emberVersion: _ember.default.computed(function () {
      let [major, minor] = _ember.default.VERSION.split(".");

      return `${major}.${minor}.0`;
    }),
    canAngleBracket: _ember.default.computed(function () {
      return true;
    }),
    isModuleUnification: _ember.default.computed(function () {
      const config = _ember.default.getOwner(this).resolveRegistration('config:environment');

      return config && config.isModuleUnification;
    })
  });

  _exports.default = _default;
});