define("ember-ajax/services/ajax", ["exports", "@ember/service", "ember-ajax/mixins/ajax-request"], function (_exports, _service, _ajaxRequest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.AjaxServiceClass = _exports.default = void 0;

  const AjaxService = _service.default.extend(_ajaxRequest.default);

  var _default = AjaxService; // DO NOT DELETE: this is how TypeScript knows how to look up your services.

  _exports.default = _default;

  class AjaxServiceClass extends AjaxService {}

  _exports.AjaxServiceClass = AjaxServiceClass;
});