define("@ember/-internals/overrides/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.onRunloopDotAccess = _exports.onComputedDotAccess = _exports.onEmberGlobalAccess = void 0;
  var onEmberGlobalAccess;
  _exports.onEmberGlobalAccess = onEmberGlobalAccess;
  var onComputedDotAccess;
  _exports.onComputedDotAccess = onComputedDotAccess;
  var onRunloopDotAccess;
  _exports.onRunloopDotAccess = onRunloopDotAccess;

  function once(callback) {
    var called = false;
    return (...args) => {
      if (called) {
        return null;
      } else {
        called = true;
        return callback(...args);
      }
    };
  }

  function onDotAccess(dotKey, importKey, module) {
    var message = 'Using `' + dotKey + '` has been deprecated. Instead, import the value directly from ' + module + ':\n\n' + '  import { ' + importKey + ' } from \'' + module + '\';\n\n' + 'These usages may be caused by an outdated ember-cli-babel dependency. ' + 'Usages of the Ember Global may be caused by an outdated ember-cli-babel dependency. ' + 'The following steps may help:\n\n' + "* Upgrade the following addons to the latest version:\n  * ember-attacher\n  * ember-jsignature\n";

    if (!false) {
      message += '\n### Important ###\n\n' + 'In order to avoid repeatedly showing the same deprecation messages, ' + 'no further deprecation messages will be shown for theses deprecated usages ' + 'until ember-cli-babel is upgraded to v7.26.6 or above.\n\n' + 'To see all instances of this deprecation message, ' + 'set the `EMBER_RUNLOOP_AND_COMPUTED_DOT_ACCESS_DEPRECATIONS` environment variable to "all", ' + 'e.g. `EMBER_RUNLOOP_AND_COMPUTED_DOT_ACCESS_DEPRECATIONS=all ember test`.\n';
    }

    message += "\n### Details ###\n\nPrior to v7.26.6, ember-cli-babel sometimes transpiled imports into the equivalent Ember Global API, potentially triggering this deprecation message indirectly, even when you did not observe these deprecated usages in your code.\n\nThe following outdated versions are found in your project:\n\n* ember-cli-babel@6.18.0, currently used by:\n  * ember-jsignature@1.0.0\n    * Depends on ember-cli-babel@^6.9.0\n  * ember-maybe-import-regenerator@0.1.6 (Dormant)\n    * Depends on ember-cli-babel@^6.0.0-beta.4\n  * ember-papaparse@1.0.0 (Dormant)\n    * Depends on ember-cli-babel@^6.6.0\n  * ember-raf-scheduler@0.1.0\n    * Depends on ember-cli-babel@^6.6.0\n    * Added by ember-attacher@1.3.0 > ember-popper@0.11.3\n\nNote: Addons marked as \"Dormant\" does not appear to have any JavaScript files. Therefore, even if they are using an old version ember-cli-babel, they are unlikely to be the culprit of this deprecation and can likely be ignored.\n";
    return message;
  }

  _exports.onEmberGlobalAccess = onEmberGlobalAccess = function onEmberGlobalAccess() {
    return "Usage of the Ember Global is deprecated. You should import the Ember module or the specific API instead.\n\nSee https://deprecations.emberjs.com/v3.x/#toc_ember-global for details.\n\nUsages of the Ember Global may be caused by an outdated ember-cli-babel dependency. The following steps may help:\n\n* Upgrade the following addons to the latest version:\n  * ember-attacher\n  * ember-jsignature\n\n### Important ###\n\nIn order to avoid repeatedly showing the same deprecation messages, no further deprecation messages will be shown for usages of the Ember Global until ember-cli-babel is upgraded to v7.26.6 or above.\n\nTo see all instances of this deprecation message, set the `EMBER_GLOBAL_DEPRECATIONS` environment variable to \"all\", e.g. `EMBER_GLOBAL_DEPRECATIONS=all ember test`.\n\n### Details ###\n\nPrior to v7.26.6, ember-cli-babel sometimes transpiled imports into the equivalent Ember Global API, potentially triggering this deprecation message indirectly, even when you did not observe these deprecated usages in your code.\n\nThe following outdated versions are found in your project:\n\n* ember-cli-babel@6.18.0, currently used by:\n  * ember-jsignature@1.0.0\n    * Depends on ember-cli-babel@^6.9.0\n  * ember-maybe-import-regenerator@0.1.6 (Dormant)\n    * Depends on ember-cli-babel@^6.0.0-beta.4\n  * ember-papaparse@1.0.0 (Dormant)\n    * Depends on ember-cli-babel@^6.6.0\n  * ember-raf-scheduler@0.1.0\n    * Depends on ember-cli-babel@^6.6.0\n    * Added by ember-attacher@1.3.0 > ember-popper@0.11.3\n\nNote: Addons marked as \"Dormant\" does not appear to have any JavaScript files. Therefore, even if they are using an old version ember-cli-babel, they are unlikely to be the culprit of this deprecation and can likely be ignored.\n";
  };

  _exports.onComputedDotAccess = onComputedDotAccess = onDotAccess;
  _exports.onRunloopDotAccess = onRunloopDotAccess = onDotAccess;

  if (!false) {
    _exports.onEmberGlobalAccess = onEmberGlobalAccess = once(onEmberGlobalAccess);
  }

  if (!false) {
    _exports.onComputedDotAccess = onComputedDotAccess = once(onComputedDotAccess);
    _exports.onRunloopDotAccess = onRunloopDotAccess = once(onRunloopDotAccess);
  }

  ;
});